import React from 'react';

const Footer = ({theme}) => {
    return (
        <div className="AppFooter">
            If you are unable to login, or need an account, you may call{' '}
            {theme.site_support_phone} for assistance or contact Customer Service at{' '}
            <a href={'mailto:' + theme.site_support_email}>{theme.site_support_email}</a>
        </div>
    )
}

export default Footer;