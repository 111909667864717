import React, {Fragment} from 'react';
import {AppButton} from '../Controls';
import {ReactComponent as WarnIcon} from '../../img/icon/exclamation-triangle-solid.svg';
import './PaneMfaSetup.css';

export default function PaneMfaSetup(props) {
    const {setMode, locked} = props;

    return <Fragment>
        <div className="AppHeader PaneMfaSetupAppHeader">
            <div style={{marginTop:'10px'}}>
                <WarnIcon style={{width:'20px',height:'20px'}} />
                <span>Set up Email Authentication</span>
            </div>
        </div>
        <div className="AppSubHeader PaneMfaSetupAppSubHeader">
            Your organization has added another level of security to your account by requiring email authentication in addition to your log in.
        </div>
        <div className="AppBody">
            <div className="AppBodyCentered PaneMfaSetup">
                <div className="PaneSubHeader">
                    Click the “Set Up” button to complete the Email Authentication set up.
                </div>
                <div className="AppControls">
                    <AppButton type="rounded"
                               text="Set Up"
                               disabled={locked}
                               onClick={() => setMode('goToMfaSetupEmail')}
                    />
                </div>
            </div>
        </div>
    </Fragment>;
}