export default class Color {
    constructor(r, g, b) {
        this.r = 0;
        this.g = 0;
        this.b = 0;

        this.set(r, g, b);
    }

    set(r, g, b) {
        g = typeof g === 'number' ? g : null;
        b = typeof b === 'number' ? b : null;

        if (g === null && b === null) {
            if (r instanceof Color) {
                this.r = r.r;
                this.g = r.g;
                this.b = r.b;
                return this;
            }

            if (   typeof r === 'string'
                && r.length === 7
                && r[0] === '#'
            ) {
                this.r = parseInt(r.slice(1, 3), 16);
                this.g = parseInt(r.slice(3, 5), 16);
                this.b = parseInt(r.slice(5, 7), 16);

                this.r = isNaN(this.r) ? 0 : this.r;
                this.g = isNaN(this.g) ? 0 : this.g;
                this.b = isNaN(this.b) ? 0 : this.b;
                return this;
            }

            if (typeof r === 'number') {
                this.r = (r & 0xff0000) >> 16;
                this.g = (r & 0x00ff00) >> 8;
                this.b = r & 0x0000ff;
                return this;
            }
        }

        this.r = typeof r === 'number' ? Math.floor(Math.abs(r)) % 256 : 0;
        this.g = g !== null ? Math.floor(Math.abs(g)) % 256 : 0;
        this.b = b !== null ? Math.floor(Math.abs(b)) % 256 : 0;
        return this;
    }

    toHex() {
        return (this.r << 16) +
            (this.g << 8) +
            this.b;
    }

    toHexString() {
        return '#' +
            (this.r < 16 ? '0' : '') + this.r.toString(16) +
            (this.g < 16 ? '0' : '') + this.g.toString(16) +
            (this.b < 16 ? '0' : '') + this.b.toString(16);
    }

    toRgbString() {
        return 'rgb(' +
            this.r + ',' +
            this.g + ',' +
            this.b + ')';
    }

    blend(color, weight) {
        if (!(color instanceof Color)) {
            color = new Color(color);
        }

        weight = typeof weight === 'number' ? weight : 0;
        weight = weight < 0 ? 0 : (weight > 1 ? 1 : weight);

        return new Color(
            Math.floor(this.r * (1 - weight) + color.r * weight),
            Math.floor(this.g * (1 - weight) + color.g * weight),
            Math.floor(this.b * (1 - weight) + color.b * weight)
        );
    }
}

export function getCssRule(selectorText) {
    let i, j;

    for (i = 0; i < document.styleSheets.length; i ++) {
        for (j = 0; j < document.styleSheets[i].rules.length; j ++) {
            if (document.styleSheets[i].rules[j].selectorText === selectorText) {
                return document.styleSheets[i].rules[j];
            }
        }
    }

    return null;
}