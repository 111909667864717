import React from 'react';
import {AppButton} from './Controls';
import {ReactComponent as CloseIcon} from "../img/icon/closeIcon.svg";
import {ReactComponent as Spinner} from "../img/icon/Rolling-1s-44px.svg";
import {ReactComponent as WarnIcon} from '../img/icon/exclamation-triangle-solid.svg';
import './Trusted.css';

function TrustedBody(props) {
    const {cancel, request, locked} = props;

    return <div className="TrustedDialogBody">
        <div className="Header16" style={{marginTop:'20px'}}>
            If so, you may want to add this to your Trusted Devices.
        </div>
        <div className="TrustedDialogSection">
            <div>A Trusted Device is a combination of a specific device (mobile phone or computer) and a web browser (Safari, Google Chrome, etc.) that can be used to provide an extra layer of security instead of entering a verification code.</div>
            <div style={{marginTop:'10px'}}>When you make this a Trusted Device a cookie will be placed in your current browser. After that, whenever you log in you will not have to enter a verification code.</div>
        </div>
        <div className="TrustedDialogNotice">
            <div className="TrustedDialogNoticeText">
                Do not make this a Trusted Device if you share this device with other users.
            </div>
        </div>
        <div className="TrustedDialogNotice">
            <div className="TrustedDialogNoticeText">
                If you are logging in on a different device or on this device, but with a different browser, you will have to also add that as a Trusted Device to avoid having to enter the verification code.
            </div>
        </div>
        <div className="TrustedDialogFooter" style={{display:'flex'}}>
            <div style={{flex:'1 1 auto'}}>
                <AppButton type="outline"
                           text="No Thanks"
                           disabled={locked}
                           onClick={() => cancel()}
                />
            </div>
            <div>
                {locked && <Spinner style={{margin:"0 120px 0 0"}} />}
                {!locked && <AppButton type="rounded"
                           text="Yes, Add this to my Trusted Devices"
                           disabled={locked}
                           onClick={() => request()}
                />}
            </div>

        </div>
    </div>;
}

function TrustedErrorBody(props) {
    const {request, cancel, locked} = props;

    return <div className="TrustedDialogErrorBody">
        <div className="TrustedDialogSection" style={{marginTop:'20px'}}>
            <div>The system is unable to add this to your trusted devices at this time.</div>
            <div style={{marginTop:'10px'}}>You can try again or skip this and continue to the application. You will be prompted to make this a Trusted Device the next time you log in.</div>
        </div>
        <div className="TrustedDialogFooter" style={{display:'flex',marginTop:'40px'}}>
            <div style={{flex:'1 1 auto'}}>
                {locked && <Spinner style={{margin:"0 0 0 35px"}} />}
                {!locked && <AppButton type="outline"
                           text="Try Again"
                           onClick={() => request()}
                />}
            </div>
            <div>
                <AppButton type="rounded"
                           text="Skip and Continue"
                           disabled={locked}
                           onClick={() => cancel()}
                />
            </div>

        </div>
    </div>;
}

export default function Trusted(props) {
    const {showTrustedError, cancel, request, locked} = props;
    const title = showTrustedError
        ? 'There was a problem making this a Trusted Device'
        : 'Do you use this device to log in every time?';
    const icon = showTrustedError
        ? <WarnIcon style={{width:'20px',height:'20px',verticalAlign:'middle',marginRight:'7px'}} />
        : null;

    return <div className="AppMask">
        <div className="TrustedDialog">
            <div style={{display:'flex'}}>
                <div className="Header20" style={{flex:'1 1 auto',padding:'20px 0 0 20px'}}>{icon}{title}</div>
                <div style={{padding:'10px 10px 0 0'}}>
                    <CloseIcon className="DialogCloseBtn"
                               onClick={() => cancel()}
                    />
                </div>
            </div>
            {showTrustedError
                ? <TrustedErrorBody request={request} cancel={cancel} locked={locked} />
                : <TrustedBody request={request} cancel={cancel} locked={locked} />}
        </div>
    </div>;
}