import React, {Fragment} from 'react';
import Notice from '../Notice';
import {AppButton, AppField} from '../Controls';
import {ReactComponent as Spinner} from "../../img/icon/Rolling-1s-44px.svg";
import './PaneMfaSetupEmail.css';

export default function PaneMfaSetupEmail(props) {
    const {email, defaultEmail, updateEmail, requestSetupMfaEmail, emailPattern, locked} = props;
    const valid = /^\S+@\S+\.\S+$/.test(email);
    let patternMsg = null;

    if (emailPattern && emailPattern.length > 0) {
        patternMsg = <span>
                You must use an email address at one of the following domain(s):<br />
            {emailPattern.join(', ')}
        </span>;
    }

    return <Fragment>
        <div className="AppHeader">
            Set up Email Authentication
        </div>
        <div className="AppBody">
            <div className="AppBodyCentered PaneMfaSetupEmail">
                <div className="PaneHeader">Step 1.</div>
                <div className="PaneSubHeader">
                    Verify your email address by clicking the Send Verification Code button. You will receive a verification code at this email address that you will enter on the next step.<br /><br />
                    If this is not the correct email address you can change it below.
                </div>
                {patternMsg && <div style={{textAlign:'center',marginBottom:'20px'}}>
                    <Notice noticeType="error" text={patternMsg} />
                </div>}
                <div style={{textAlign:'center'}}>
                    <AppField
                        title="Email Address"
                        type="text"
                        value={email}
                        disabled={locked}
                        onChange={e => updateEmail(e.target.value)}
                        onKeyPress={e => {
                            e.key === 'Enter'
                                && valid
                                && requestSetupMfaEmail();
                        }}
                    />
                </div>
                {defaultEmail !== '' && <div style={{textAlign:'center',marginTop:'10px',visibility:email === defaultEmail ? 'hidden' : 'visible'}}>
                    <AppButton type="link"
                               text="Reset to default email address"
                               onClick={() => updateEmail(defaultEmail)}
                    />
                </div>}
                <div className="AppControls">
                    {locked && <Spinner />}
                    {!locked && <AppButton type="rounded"
                                           text="Send Verification Code"
                                           disabled={locked || !valid}
                                           onClick={requestSetupMfaEmail}
                    />}
                </div>
            </div>
        </div>
    </Fragment>;
}