import React, {Component} from 'react';
import {ReactComponent as UserIcon} from '../img/icon/user.svg';
import {ReactComponent as LockIcon} from '../img/icon/lock.svg';
import {ReactComponent as EmailIcon} from '../img/icon/envelope-regular.svg';
import {ReactComponent as WarnIcon} from "../img/icon/exclamation-circle-solid.svg";
import {ReactComponent as ValidIcon} from "../img/icon/check-circle-solid.svg";
import {ReactComponent as ErrorIcon} from "../img/icon/times-circle-solid.svg";
import './Controls.css';

export class AppField extends Component {
    constructor(props) {
        super(props);

        this.state = {focused:false};
    }

    render() {
        const {title, type, value, icon, disabled, onChange, onKeyPress, autoComplete} = this.props;
        const {focused} = this.state;

        if (type === 'static') {
            return <div className="AppField AppFieldStatic">
                <div className="AppFieldLabel">{title}</div>
                {icon === 'user' && <UserIcon className="AppFieldIcon" />}
                {icon === 'lock' && <LockIcon className="AppFieldIcon" />}
                {icon === 'email' && <EmailIcon className="AppFieldIcon" />}
                <input type="text"
                       value={value}
                       style={{cursor:'default'}}
                       disabled={true}
                       autoComplete="new-off"
                />
            </div>;
        }
        return <div className={'AppField' + (disabled ? ' AppFieldDisabled' : '')}>
            <div className={'AppFieldLabel' + (icon ? ' Icon' : '')}
                 style={{visibility:focused || (typeof value === 'string' && value !== '') ? 'visible' : 'hidden'}}
            >{title}</div>
            {icon === 'user' && <UserIcon className="AppFieldIcon" />}
            {icon === 'lock' && <LockIcon className="AppFieldIcon" />}
            {icon === 'email' && <EmailIcon className="AppFieldIcon" />}
            <input type={type}
                   value={value}
                   placeholder={focused ? '' : title}
                   style={{cursor:disabled ? 'default' : (focused ? 'auto' : 'pointer')}}
                   disabled={disabled}
                   onFocus={() => this.setState({focused:true})}
                   onBlur={() => this.setState({focused:false})}
                   onChange={onChange}
                   onKeyPress={onKeyPress}
                   autoComplete={autoComplete}
            />
        </div>;
    }
}

export function AppButton(props) {
    const {type, text, disabled, onClick} = props;
    const css = disabled === true ? 'disabled' : 'active';

    return <button className={type + ' ' + css}
                   disabled={disabled}
                   onClick={onClick}
    >{text}</button>;
}

export function AppCheckbox(props) {
    const {label, checked, disabled, onChange} = props;
    const css = disabled === true ? ' disabled' : '';

    return <div className={'AppCheckbox' + css}>
        <input type="checkbox"
               checked={checked}
               disabled={disabled}
               onChange={onChange}
        /> <span className="AppCheckboxLabel">{label}</span>
    </div>;
}

export function AppRadio(props) {
    const {label, checked, disabled, onChange} = props;

    return <div className={'AppRadio' + (disabled ? ' AppRadioDisabled' : '')}>
        <input type="radio"
               checked={checked}
               disabled={disabled}
               onChange={onChange}
        /> <span className="AppRadioLabel">{label}</span>
    </div>;
}

export function AppSelect(props) {
    const {label, items, value, disabled, onChange} = props;

    return <select className={'AppSelect' + (disabled ? ' AppSelectDisabled' : '')}
                   value={value}
                   disabled={disabled}
                   onChange={onChange}
    >
        {!value && <option>{label}</option>}
        {items.map((i) => <option key={i.id} value={i.id}>{i.text}</option>)}
    </select>;
}

export class AppSecurityCode extends Component {
    constructor(props) {
        super(props);

        this.state = {focused:0};

        this.ref = [
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef()
        ];
    }

    componentDidMount() {
        const { focused } = this.state;
        this.ref[focused].current.select();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { focused } = this.state;
        focused !== null && window.setTimeout(() => {
            this.ref[focused].current.select();
        }, 10);
    }

    onDigit(evt, ix) {
        const { onChange, value } = this.props;
        const { key } = evt;
        const digits = ['0','1','2','3','4','5','6','7','8','9'];
        const digit = value.split('');

        if (digits.indexOf(key) >= 0) {
            digit[ix] = key;
            this.setState({
                focused: ix < 5 ? ix + 1 : null
            });

            if (ix === 5) {
                this.ref[ix].current.blur();
            }

            onChange && onChange(digit.join(''));
        } else if (key === 'Backspace') {
            digit[ix] = '';
            this.setState({
                focused: ix > 0 ? ix - 1 : ix
            });

            onChange && onChange(digit.join(''));
        }
    }

    selectDigit(evt, ix) {
        this.ref[ix].current.select();
        evt.preventDefault();
    }

    pasteDigits(evt, ix) {
        const { onChange, value } = this.props;
        const digit = value.split('');
        const maxLen = 6 - ix;
        let clipboardText = (evt.clipboardData || window.clipboardData)
            .getData('text');
        let pasteData = '';
        let charCode;
        let newFocus = null;

        while (clipboardText.length > 0 && pasteData.length < maxLen) {
            charCode = clipboardText.charCodeAt(0);
            if (charCode >= 48 && charCode <= 57) {
                pasteData += clipboardText[0];
            }
            clipboardText = clipboardText.slice(1);
        }

        if (pasteData.length > 0) {
            for (let i = 0; i < maxLen; i ++) {
                if (i >= pasteData.length) {
                    digit[i + ix] = '';
                    if (newFocus === null) {
                        newFocus = i + ix;
                    }
                } else {
                    digit[i + ix] = pasteData[i];
                }
            }

            this.setState({
                focused: newFocus ? newFocus : null
            });

            if (newFocus === null) {
                this.ref[ix].current.blur();
            }

            onChange && onChange(digit.join(''));
        }

        evt.preventDefault();
    }

    render() {
        const { value } = this.props;
        const digit = value.split('');

        while (digit.length < 6) {
            digit.push('');
        }

        return <div className="AppSecurityCode">
            {digit.map(
                (d, ix) => <input key={ix}
                                  ref={this.ref[ix]}
                                  type="number"
                                  className="AppSecurityCodeDigit"
                                  value={d}
                                  onKeyDown={e => this.onDigit(e, ix)}
                                  onChange={() => {}}
                                  onMouseDown={e => this.selectDigit(e, ix)}
                                  onPaste={e => this.pasteDigits(e, ix)}
                />
            )}
        </div>;
    }
}

export class AppPasswordValidate extends Component {
    static validate(pass, confirm) {
        return {
            length:  pass === '' ? null : pass.length >= 8,
            number:  pass === '' ? null : pass.match(/[0-9]/) !== null,
            upper:   pass === '' ? null : pass.match(/[A-Z]/) !== null,
            lower:   pass === '' ? null : pass.match(/[a-z]/) !== null,
            special: pass === '' ? null : pass.match(/\W/) !== null,
            match:   pass === '' ? null : pass === confirm
        };
    }

    render() {
        const { valid } = this.props;

        const warnIcon = <WarnIcon style={{width:'18px',height:'18px'}} />;
        const validIcon = <ValidIcon style={{width:'18px',height:'18px'}} />;
        const errorIcon = <ErrorIcon style={{width:'18px',height:'18px'}} />;

        const length = valid.length === null ? warnIcon : (valid.length ? validIcon : errorIcon);
        const number = valid.number === null ? warnIcon : (valid.number ? validIcon : errorIcon);
        const upper = valid.upper === null ? warnIcon : (valid.upper ? validIcon : errorIcon);
        const lower = valid.lower === null ? warnIcon : (valid.lower ? validIcon : errorIcon);
        const special = valid.special === null ? warnIcon : (valid.special ? validIcon : errorIcon);
        const match = valid.match === null ? warnIcon : (valid.match ? validIcon : errorIcon);

        return <div className="AppPasswordValid">
            <div className="AppPasswordValidTitle">Password Criteria:</div>
            <table cellSpacing="0">
                <tbody>
                <tr><th>{length}</th><td>At least 8 characters</td></tr>
                <tr><th>{number}</th><td>One number</td></tr>
                <tr><th>{upper}</th><td>One UPPERCASE character</td></tr>
                <tr><th>{lower}</th><td>One lowercase character</td></tr>
                <tr><th>{special}</th><td>One special character (!,$,%,@,#)</td></tr>
                <tr><th>{match}</th><td>Password and confirm must match</td></tr>
                </tbody>
            </table>
        </div>;
    }
}