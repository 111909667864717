import React, {Fragment} from 'react';
import {AppButton} from '../Controls';
import './PaneRecoverSuccess.css';

export default function PaneRecoverSuccess(props) {
    const { setNotice, doAction, setUsername, setPassword } = props;

    return <Fragment>
        <div className="AppHeader PaneRecoverSuccessAppHeader">
            If that username and security answer combo is in our database, we will send you an email to reset your password.
        </div>
        <div className="AppBody">
            <div className="AppBodyCentered PaneRecoverSuccess">
                <div className="AppControls">
                    <AppButton type="rounded"
                               text="Back to Login"
                               onClick={() => {
                                   setNotice();
                                   setUsername('');
                                   setPassword('');
                                   doAction('goToLogin');
                               }}
                    />
                </div>
            </div>
        </div>
    </Fragment>;
}