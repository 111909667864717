import React, {Fragment} from 'react';
import {AppField, AppButton, AppSelect} from '../Controls';
import {ReactComponent as Spinner} from "../../img/icon/Rolling-1s-44px.svg";
import './PaneFirstLogin.css';

export default function PaneFirstLogin(props) {
    const {questions, questionId, setQuestionId, answer, setAnswer, requestUpdateFirstLogin, locked} = props;
    const btnDisabled = questionId === null || answer === '';

    return <Fragment>
        <div className="AppHeader">
            Choose Security Question
        </div>
        <div className="AppSubHeader">
            For added security, choose a security question and an answer.
        </div>
        <div className="AppBody">
            <div className="AppBodyCentered PaneFirstLogin">
                <div className="PaneFirstLoginQuestion">
                    <AppSelect label="Choose security question"
                               items={questions}
                               value={questionId}
                               disabled={locked || questions.length === 0}
                               onChange={e => setQuestionId(e.target.value)}
                    />
                </div>
                <div className="PaneFirstLoginQuestion">
                    <AppField title="Enter answer"
                              type="text"
                              value={answer}
                              disabled={locked || questions.length === 0 || questionId === null}
                              onChange={e => setAnswer(e.target.value)}
                              onKeyPress={e => {
                                  e.key === 'Enter'
                                      && !btnDisabled
                                      && requestUpdateFirstLogin();
                              }}
                    />
                </div>
                <div className="AppControls">
                    {locked && <Spinner />}
                    {!locked && <AppButton type="rounded"
                                           text="Save"
                                           disabled={locked || btnDisabled}
                                           onClick={() => requestUpdateFirstLogin()}
                    />}
                </div>
            </div>
        </div>
    </Fragment>;
}