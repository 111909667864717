import React, {Fragment} from 'react';
import {AppField, AppButton} from '../Controls';
import {ReactComponent as Spinner} from "../../img/icon/Rolling-1s-44px.svg";
import './PaneSecurityQuestion.css';

export default function PaneSecurityQuestion(props) {
    const {locked, question, answer, setAnswer, setUsername, setPassword, requestValidateSecurityAnswer, setNotice, doAction} = props;
    const btnDisabled = answer === '';

    return <Fragment>
        <div className="AppHeader">
            Having Trouble Logging In?
        </div>
        <div className="AppSubHeader">
            Enter the answer to the security question you selected when you created this account.
        </div>
        <div className="AppBody">
            <div className="AppBodyCentered PaneSecurityQuestion">
                <div className="PaneSecurityQuestionQuestion">
                    <AppField title="Security Question"
                              type="static"
                              value={question}
                    />
                </div>
                <div className="PaneSecurityQuestionAnswer">
                    <AppField title="Enter answer"
                              type="text"
                              value={answer}
                              disabled={locked}
                              onChange={e => setAnswer(e.target.value)}
                              onKeyPress={e => {
                                  e.key === 'Enter'
                                      && !btnDisabled
                                      && requestValidateSecurityAnswer();
                              }}
                    />
                </div>
                <div className="AppControls">
                    {locked && <Spinner />}
                    {!locked && <AppButton type="rounded"
                                           text="Continue"
                                           disabled={locked || btnDisabled}
                                           onClick={() => requestValidateSecurityAnswer()}
                    />}
                    <div className="PaneSecurityQuestionCancel">
                        <AppButton type="link"
                                   text="Cancel"
                                   disabled={locked}
                                   onClick={() => {
                                       setNotice();
                                       setUsername('');
                                       setPassword('');
                                       doAction('goToLogin');
                                   }}
                        />
                    </div>
                </div>
            </div>
        </div>
    </Fragment>;
}