import Color, {getCssRule} from "./Color";
import request from "./Request";

export const defaultTheme = {
    color_accent: new Color('#0790c8'),
    color_button: new Color('#0790c8'),
    color_button_text: new Color('#ffffff'),
    color_link: new Color('#0790c8')
};

export function getTheme(siteKey) {
    const params = {http_host: window.location.host};

    if (siteKey) {
        params.site_key = siteKey;
    }

    return new Promise((resolve, reject) => {
        request(
            '/api/login/custom',
            JSON.stringify(params),
            'application/json;charset=UTF-8'
        ).then(response => {
            const { status, data } = response;

            switch (status) {
                case 200:
                    data.color_accent = data.color_accent
                        ? new Color(data.color_accent)
                        : defaultTheme.color_accent;

                    data.color_button = data.color_button
                        ? new Color(data.color_button)
                        : defaultTheme.color_button;

                    data.color_button_text = data.color_button_text
                        ? new Color(data.color_button_text)
                        : defaultTheme.color_button_text;

                    data.color_link = data.color_link
                        ? new Color(data.color_link)
                        : defaultTheme.color_link;

                    resolve(data);
                    break;

                default:
                    reject(status);
                    break;
            }
        }).catch(response => {
            reject(response);
        });
    });
}

export function updateTheme(theme) {
    const svg = document.getElementById('Layer_1');
    const color0Stop = document.getElementById('bgGradStop0');
    const color1Stop = document.getElementById('bgGradStop1');
    const color2Stop = document.getElementById('bgGradStop2');

    // compute colors from theme colors

    const black = new Color();
    const white = new Color(0xffffff);

    const stop2Color = theme.color_accent.blend(white, 0.7);
    const stop1Color = stop2Color.blend(white, 0.5);
    const stop0Color = white;

    const btnPressColor = theme.color_button.blend(black, 0.25);
    const linkPressColor = theme.color_link.blend(white, 0.25);

    // update svg

    color0Stop.style.stopColor = stop0Color.toHexString();
    color1Stop.style.stopColor = stop1Color.toHexString();
    color2Stop.style.stopColor = stop2Color.toHexString();

    // this is a hack.  chrome does not repaint the svg after
    // changing the values of the color stops in css rules
    // for the linear gradient.
    svg.style.display = ''; // <-- HACK ALERT! THANKS CHROME. THANKS OBAMA.

    // update stylesheet rules

    let rule;

    rule = getCssRule('.AppAccent');
    if (rule) {
        rule.style.backgroundColor = theme.color_accent.toHexString();
    }

    rule = getCssRule('button.rounded.active')
        || getCssRule('button.active.rounded');
    if (rule) {
        rule.style.backgroundColor = theme.color_button.toHexString();
        rule.style.color = theme.color_button_text.toHexString();
    }

    rule = getCssRule('button.rounded.active:active')
        || getCssRule('button.active.rounded:active');
    if (rule) {
        rule.style.backgroundColor = btnPressColor.toHexString();
    }

    rule = getCssRule('button.outline.active')
        || getCssRule('button.active.outline');
    if (rule) {
        rule.style.borderColor = theme.color_button.toHexString();
        rule.style.color = theme.color_button.toHexString();
    }

    rule = getCssRule('button.outline.active:active')
        || getCssRule('button.active.outline:active');
    if (rule) {
        rule.style.borderColor = btnPressColor.toHexString();
        rule.style.backgroundColor = btnPressColor.toHexString();
        rule.style.color = theme.color_button_text.toHexString();
    }

    rule = getCssRule('button.link.active')
        || getCssRule('button.active.link');
    if (rule) {
        rule.style.color = theme.color_link.toHexString();
    }

    rule = getCssRule('button.link.active:active')
        || getCssRule('button.active.link:active');
    if (rule) {
        rule.style.color = linkPressColor.toHexString();
    }

    rule = getCssRule('a');
    if (rule) {
        rule.style.color = theme.color_link.toHexString();
    }

    rule = getCssRule('a:active');
    if (rule) {
        rule.style.color = linkPressColor.toHexString();
    }

    rule = getCssRule('.AppSecurityCodeDigit:focus');
    if (rule) {
        rule.style.borderColor = theme.color_accent.toHexString();
    }
}