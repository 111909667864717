import React, {Fragment} from 'react';
import {AppField, AppButton} from '../Controls';
import {ReactComponent as Spinner} from "../../img/icon/Rolling-1s-44px.svg";
import './PaneForgotPassword.css';

export default function PaneForgotPassword(props) {
    const {username, setUsername, setPassword, setNotice, doAction, requestValidateUsername, locked} = props;
    const btnDisabled = username === '';

    return <Fragment>
        <div className="AppHeader">
            Having Trouble Logging In?
        </div>
        <div className="AppSubHeader">
            Enter your username to recover your password.
        </div>
        <div className="AppBody">
            <div className="AppBodyCentered PaneForgotPassword">
                <div className="PaneForgotPasswordUser">
                    <AppField title="Enter username"
                              type="text"
                              value={username}
                              icon="user"
                              disabled={locked}
                              onChange={e => setUsername(e.target.value)}
                              onKeyPress={e => {
                                  e.key === 'Enter'
                                      && !btnDisabled
                                      && requestValidateUsername();
                              }}
                    />
                </div>
                <div className="AppControls">
                    {locked && <Spinner />}
                    {!locked && <AppButton type="rounded"
                                           text="Continue"
                                           disabled={locked || btnDisabled}
                                           onClick={() => requestValidateUsername()}
                    />}
                    <div style={{marginTop:'20px'}}>
                        <AppButton type="link"
                                   text="Cancel"
                                   disabled={locked}
                                   onClick={() => {
                                       setNotice();
                                       setUsername('');
                                       setPassword('');
                                       doAction('goToLogin');
                                   }}
                        />
                    </div>
                </div>
            </div>
        </div>
    </Fragment>;
}