import React, {Fragment} from 'react';
import {AppField, AppButton, AppCheckbox} from "../Controls";
import {ReactComponent as Spinner} from '../../img/icon/Rolling-1s-44px.svg';
import './PaneLogin.css';

export default function PaneLogin(props) {
    const {username, setUsername, password, setPassword, remember, setRemember, setNotice, doAction, requestLogin, theme, locked} = props;
    const btnDisabled = username === '' || password === '';

    return <Fragment>
        <div className="AppHeader">
            If you are a current {theme.site_company_abbr} user, please sign in.
        </div>
        <div className="AppSubHeader">
            {theme.site_welcome}
        </div>
        <div className="AppBody">
            <div className="AppBodyCentered PaneLogin">
                <div>
                    <AppField title="Username"
                              type="text"
                              value={username}
                              icon="user"
                              disabled={locked}
                              onChange={e => setUsername(e.target.value)}
                              onKeyPress={e => {
                                  e.key === 'Enter'
                                  && username !== ''
                                  && password !== ''
                                  && requestLogin();
                              }}
                              autoComplete="new-username"
                    />
                </div>
                <div className="AppFieldLast">
                    <AppField title="Password"
                              type="password"
                              value={password}
                              icon="lock"
                              disabled={locked}
                              onChange={e => setPassword(e.target.value)}
                              onKeyPress={e => {
                                  e.key === 'Enter'
                                  && username !== ''
                                  && password !== ''
                                  && requestLogin();
                              }}
                              autoComplete="new-password"
                    />
                </div>
                <AppCheckbox label="Remember me"
                             checked={remember}
                             disabled={locked}
                             onChange={e => setRemember(e.target.checked)}
                />
                <div className="AppControls">
                    {locked && <Spinner />}
                    {!locked && <AppButton type="rounded"
                                           text="Log In"
                                           disabled={btnDisabled}
                                           onClick={() => requestLogin()}
                    />}
                    <p>
                        Forgot your password?
                        <AppButton type="link"
                                   text="Click here"
                                   onClick={() => {
                                       setNotice();
                                       setUsername('');
                                       setPassword('');
                                       doAction('goToForgotPassword');
                                   }}
                        />
                    </p>
                </div>
            </div>
        </div>
    </Fragment>;
}