import React, {Fragment} from 'react';
import {AppField, AppButton, AppPasswordValidate} from '../Controls';
import {ReactComponent as Spinner} from "../../img/icon/Rolling-1s-44px.svg";
import './PaneChangePassword.css';

export default function PaneChangePassword(props) {
    const {user, password, setPassword, confirm, setConfirm, requestChangePassword, locked} = props;
    const valid = AppPasswordValidate.validate(password, confirm);
    const btnDisabled = valid.length !== true
        || valid.number !== true
        || valid.upper !== true
        || valid.lower !== true
        || valid.special !== true
        || valid.match !== true;

    return <Fragment>
        <div className="AppHeader">
            Change Password
        </div>
        <div className="AppSubHeader PaneChangePassword">
            {
                user.isFirstLogin
                    ? 'You logged in with a temporary password.'
                    : 'Your password has expired.'
            } You will need to create a new password below.
        </div>
        <div className="AppBody">
            <div className="AppBodyCentered PaneChangePassword">
                <div className="AppChangePasswordBody">
                    <div className="AppPasswordInput">
                        <div>
                            <AppField title="Password"
                                      type="password"
                                      value={password}
                                      icon="lock"
                                      disabled={locked}
                                      onChange={e => {
                                          setPassword(e.target.value);
                                          if (e.target.value === '') {
                                              setConfirm('');
                                          }
                                      }}
                                      onKeyPress={e => {
                                          e.key === 'Enter'
                                              && !btnDisabled
                                              && requestChangePassword();
                                      }}
                            />
                        </div>
                        <div className="AppFieldLast">
                            <AppField title="Confirm Password"
                                      type="password"
                                      value={confirm}
                                      icon="lock"
                                      disabled={locked || password === ''}
                                      onChange={e => setConfirm(e.target.value)}
                                      onKeyPress={e => {
                                          e.key === 'Enter'
                                              && !btnDisabled
                                              && requestChangePassword();
                                      }}
                            />
                        </div>
                    </div>
                    <div className="AppControls">
                        {locked && <Spinner />}
                        {!locked && <AppButton type="rounded"
                                               text="Save"
                                               disabled={locked || btnDisabled}
                                               onClick={() => requestChangePassword()}
                        />}
                    </div>
                    <AppPasswordValidate valid={valid} />
                </div>
                <div className="AppControls">
                    {locked && <Spinner />}
                    {!locked && <AppButton type="rounded"
                                           text="Save"
                                           disabled={locked || btnDisabled}
                                           onClick={() => requestChangePassword()}
                    />}
                </div>
            </div>
        </div>
    </Fragment>;
}