import React from 'react';
import {AppButton} from './Controls';
import {ReactComponent as GearIcon} from '../img/icon/cog-solid.svg';
import './Error.css';

export default function Maintenance(props) {
    return <div className="AppMask">
        <div className="AppDialog">
            <div className="AppDialogBody">
                <GearIcon style={{width:'30px',height:'30px'}} />
                <div className="Header16" style={{marginTop:'5px'}}>
                    Scheduled Maintenance
                </div>
                <div className="AppDialogSection">
                    Our site is currently undergoing scheduled maintenance. Please try again later.
                </div>
                <div className="AppDialogSection">
                    <AppButton type="rounded"
                               text="Try Again"
                               onClick={() => window.location.reload()}
                    />
                </div>
            </div>
        </div>
    </div>;
}