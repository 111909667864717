import React, {Component, Fragment} from 'react';
import {ReactComponent as WarningIcon} from '../img/icon/exclamation-triangle-solid.svg';

import PaneLogin from './Pane/PaneLogin';
import PaneForgotPassword from './Pane/PaneForgotPassword';
import PaneFirstLogin from './Pane/PaneFirstLogin';
import PaneSecurityQuestion from './Pane/PaneSecurityQuestion';
import PaneRecoverSuccess from './Pane/PaneRecoverSuccess';
import PaneChangePassword from './Pane/PaneChangePassword';
import PaneMfaSetup from './Pane/PaneMfaSetup';
import PaneMfaSetupEmail from './Pane/PaneMfaSetupEmail';
import PaneMfaEmail from './Pane/PaneMfaEmail';
import PaneContinueToScreen from './Pane/PaneContinueToScreen';

import Notice from './Notice';
import Error from './Error';
import Trusted from './Trusted';
import Maintenance from './Maintenance';
import Footer from "./Footer";

import {
    checkBrowser,
    getSearchParams,
    getTagEl
} from "../service/Browser";
import request, {getFormData} from '../service/Request';
import {getTheme, updateTheme} from "../service/Theme";
import Cookie from "../service/Cookie";

import './App.css';

export default class App extends Component {
    static CookieUser = 'i3screen_user';

    constructor(props) {
        super(props);

        const rememberUser = Cookie.get(App.CookieUser);

        this.state = {
            ready: false,
            user: null,
            mode: null,
            notice: null,
            locked: false,
            username: rememberUser ? rememberUser : '',
            password: '',
            confirm: '',
            remember: rememberUser !== null,
            email: '',
            defaultEmail: '',
            questions: [],
            questionId: null,
            question: '',
            answer: '',
            code: '',
            error: false,
            errorTitle: null,
            errorMessage: null,
            showTrustedSetup: false,
            showTrustedError: false,
            maintenance: false,
            emailPattern: null
        };

        this.searchParams = getSearchParams();
        this.nextAction = [];

        this.checkLoggedIn()
            .then(result => {
                if (result.isAuthorized) {
                    this.redirectToScreen();
                    return;
                }

                this.initialize();
            })
            .catch(() => this.setError('VerifyLogin'));
    }

    checkLoggedIn() {
        return fetch('/api/login/verify')
            .then(response => {
                if (response.status !== 200) {
                    throw new Error();
                }
                return response.json();
            })
    }

    redirectToScreen() {
        let redirect = window.location.origin;
        if (!redirect) {
            redirect = window.location.protocol + '//' + window.location.hostname;
            if (window.location.port) {
                redirect += ':' + window.location.port;
            }
        }

        window.location.href = redirect;
    }

    initialize() {
        window.addEventListener('focus', () => {
            this.checkLoggedIn()
                .then(result => {
                    if (result.isAuthorized) {
                        this.redirectToScreen();
                    } else if (
                        !result.isAuthenticated
                        && this.state.mode !== 'goToLogin'
                    ) {
                        window.location.reload();
                    }
                })
                .catch(() => this.setError('VerifyLoginFocus'));
        });

        getTheme(this.searchParams.key)
            .then(theme => {
                this.theme = theme;
                updateTheme(this.theme);

                const tag = getTagEl();
                tag.innerHTML = `${(theme.site_company_abbr) ? theme.site_company_abbr : "i3screen"} Login`;

                const valid = checkBrowser();
                if (valid !== true) {
                    this.setNotice('warning', valid);
                    this.doAction('goToLogin');
                    return;
                }

                if (this.searchParams.token) {
                    this.requestValidateToken();
                    return;
                }

                this.doAction('goToLogin');
            })
            .catch(response => {
                if (response.status === 503) {
                    this.setState({maintenance:true});
                } else {
                    this.setError('GetTheme', response.status);
                }
            });
    }

    initializeUser(userData) {
        const email = userData.mfaDestination
            ? userData.mfaDestination
            : '';

        this.setState({
            user: userData,
            email: email,
            defaultEmail: email,
            password: ''
        });

        if (userData.isMfaRequired === true) {
            if (userData.isMfaSetUpRequired === true) {
                this.addAction('goToMfaSetup');
            } else {
                this.addAction('goToMfaEmail');
            }
        }

        if (userData.isFirstLogin === true) {
            this.addAction('goToFirstLogin');
        }

        if (userData.isPasswordExpired === true) {
            this.addAction('goToChangePassword');
        }

        this.doNextAction();
    }

    setNotice(type, text) {
        this.setState({
            notice: type !== undefined && text !== undefined
                ? <Notice noticeType={type} text={text} />
                : null
        });
    }

    setMode(mode) {
        window.gtag('config', 'G-BHQK6X4TVB', {
            page_title: 'Login_' + mode.slice(4) + 'Pane',
            page_path: '/login/' + mode.slice(4)
        });

        this.setState({
            ready: true,
            mode: mode,
            locked: false
        });
    }

    setError(type, status, title, message) {
        if (type !== undefined && status !== undefined) {
            window.gtag('event', 'LoginError', {
                LoginAction: type,
                LoginValue: status
            });
        }

        this.setState({
            showTrustedSetup: false,
            error: true,
            errorTitle: title || null,
            errorMessage: message || null,
        });
    }

    addAction(action) {
        this.nextAction.unshift(action);
    }

    doAction(action) {
        this.addAction(action);
        this.doNextAction();
    }

    doNextAction() {
        if (this.nextAction.length === 0) {
            if (this.state.remember) {
                Cookie.set(App.CookieUser, this.state.username);
            } else {
                Cookie.clear(App.CookieUser);
            }

            this.redirectToScreen();
            return;
        }

        const next = this.nextAction.shift();

        switch (next) {
            case 'goToFirstLogin':
                this.requestGetSecurityQuestions()
                    .then(response => {
                        const { status, data } = response;

                        switch (status) {
                            case 200:
                                this.setState({questions:data.results});
                                this.setMode(next);
                                break;

                            default:
                                this.setError('GetSecurityQuestions', status);
                                break;
                        }
                    })
                    .catch(err => this.setError('GetSecurityQuestions', err.message + ' ' + err.status));
                break;

            case 'goToMfaEmail':
                this.requestSendMfaEmail()
                    .then(response => {
                        const { status, data } = response;

                        switch (status) {
                            case 200:
                                this.setState({email:data.email});
                                this.setMode(next);
                                break;

                            default:
                                this.setError('SendMfaEmail', status);
                                break;
                        }
                    })
                    .catch(err => this.setError('SendMfaEmail', err.message + ' ' + err.status));
                break;

            default:
                this.setMode(next);
                break;
        }
    }

    resendMfaEmail() {
        this.setNotice();

        this.requestSendMfaEmail()
            .then(response => {
                const { status, data } = response;

                switch (status) {
                    case 200:
                        window.gtag('event', 'Login', {
                            LoginAction: 'ResendMfaEmail',
                            LoginValue: data.email
                        });

                        this.setState({email:data.email});
                        this.setNotice('success', 'Verification code resent');
                        break;

                    default:
                        this.setError('ResendMfaEmail', status);
                        break;
                }
            })
            .catch(err => this.setError('ResendMfaEmail', err.message + ' ' + err.status));
    }

    requestValidateToken() {
        request(
            '/api/auth/passwordreset',
            JSON.stringify({
                key: this.searchParams.key,
                contactId: this.searchParams.contactId,
                username: this.searchParams.userName,
                loginToken: this.searchParams.token
            }),
            'application/json;charset=UTF-8'
        ).then(response => {
            const { status, data } = response;

            switch (status) {
                case 422:
                    window.gtag('event', 'LoginError', {
                        LoginAction: 'ValidateToken',
                        LoginValue: 'Invalid token request ' + status
                    });

                    this.setNotice('error', 'Invalid token request');
                    this.doAction('goToLogin');
                    break;

                case 401:
                    window.gtag('event', 'LoginError', {
                        LoginAction: 'ValidateToken',
                        LoginValue: data.errorMessage + ' ' + status
                    });

                    this.setNotice('error', data.errorMessage);
                    this.doAction('goToLogin');
                    break;

                case 200:
                    this.setState({username: this.searchParams.userName});
                    this.initializeUser(data);
                    break;

                default:
                    this.setError('ValidateToken', status);
                    break;
            }
        }).catch(err => this.setError('ValidateToken', err.message + ' ' + err.status));
    }

    requestLogin() {
        this.setState({
            notice: null,
            locked: true
        });

        request(
            '/api/auth/login',
            JSON.stringify({
                username: this.state.username,
                password: this.state.password
            }),
            'application/json;charset=UTF-8'
        ).then(response => {
            const { status, data } = response;

            switch (status) {
                case 422:
                    window.gtag('event', 'LoginError', {
                        LoginAction: 'Login',
                        LoginValue: 'Missing credentials ' + status
                    });

                    this.setState({
                        notice: <Notice noticeType="warning" text="Missing credentials" />,
                        locked: false
                    });
                    break;

                case 401:
                case 200:
                    if (data.success !== true) {
                        window.gtag('event', 'LoginError', {
                            LoginAction: 'Login',
                            LoginValue: data.errorMessage + ' ' + status
                        });

                        switch (data.errorCode) {
                            case 13:
                                // invalid ip address
                                this.setError(
                                    'Login',
                                    data.errorMessage,
                                    'Invalid IP Address',
                                    data.errorMessage
                                );
                                break;

                            default:
                                this.setState({
                                    notice: <Notice noticeType="error" text={data.errorMessage} />,
                                    locked: false
                                });
                                break;
                        }
                        break;
                    }

                    this.initializeUser(data);
                    break;

                default:
                    this.setError('Login', status);
                    break;
            }
        }).catch(err => this.setError('Login', err.message + ' ' + err.status));
    }

    requestValidateSecurityAnswer() {
        this.setState({
            notice: null,
            locked: true
        });

        request(
            '/buffer/ajax/loginController.php',
            getFormData({
                task: 'taskRecoverAnswer',
                frm_recover_username: this.state.username,
                frm_recover_answer: this.state.answer
            })
        )
            .then(response => {
                const { status } = response;

                switch (status) {
                    case 200:
                        this.setState({
                            username: '',
                            answer: '',
                        });
                        this.doAction('goToRecoverSuccess');
                        break;
                    default:
                        this.setError('ValidateSecurityAnswer', status);
                        break;
                }
            })
            .catch(err => this.setError('ValidateSecurityAnswer', err.message + ' ' + err.status));
    }

    requestValidateUsername() {
        this.setState({
            notice: null,
            locked: true
        });

        request(
            '/buffer/ajax/loginController.php',
            getFormData({
                task: 'taskRecoverUser',
                frm_recover_username: this.state.username
            })
        )
            .then(response => {
                const { status, data } = response;

                switch (status) {
                    case 200:
                        this.setState({ question: data.results.question });
                        this.doAction('goToSecurityQuestion');
                        break;
                    default:
                        this.setError('ValidateUsername', status);
                        break;
                }
            })
            .catch(err => this.setError('ValidateUsername', err.message + ' ' + err.status));
    }

    requestChangePassword() {
        this.setState({
            notice: null,
            locked: true
        });

        request(
            '/buffer/ajax/loginController.php',
            getFormData({
                task: 'taskNewPassword',
                frm_new_password: this.state.password,
                frm_verify_password: this.state.confirm,
                contactId: this.searchParams.contactId
            })
        ).then(response => {
            const { status, data } = response;

            switch (status) {
                case 200:
                    if (data.success === true) {
                        this.doNextAction();
                    } else {
                        window.gtag('event', 'LoginError', {
                            LoginAction: 'ChangePassword',
                            LoginValue: data.message + ' ' + status
                        });

                        this.setNotice('error', data.message);
                        this.setState({locked: false});
                    }
                    break;

                default:
                    this.setError('ChangePassword', status);
                    break;
            }
        }).catch(err => this.setError('ChangePassword', err.message + ' ' + err.status));
    }

    requestUpdateFirstLogin() {
        this.setState({
            notice: null,
            locked: true
        });

        request(
            '/buffer/ajax/loginController.php',
            getFormData({
                task: 'taskFirstLogin',
                frm_set_question: this.state.questionId,
                frm_set_answer: this.state.answer
            })
        ).then(response => {
            const { status, data } = response;

            switch (status) {
                case 200:
                    if (data.success === true) {
                        this.doNextAction();
                    } else {
                        window.gtag('event', 'LoginError', {
                            LoginAction: 'UpdateFirstLogin',
                            LoginValue: data.message + ' ' + status
                        });

                        this.setNotice('error', data.message);
                        this.setState({locked: false});
                    }
                    break;

                default:
                    this.setError('UpdateFirstLogin', status);
                    break;
            }
        }).catch(err => this.setError('UpdateFirstLogin', err.message + ' ' + err.status));
    }

    requestValidateSecurityCode() {
        this.setState({
            notice: null,
            locked: true
        });

        request(
            '/api/auth/mfa/verifycode',
            JSON.stringify({verification_code: this.state.code}),
            'application/json;charset=UTF-8'
        ).then(response => {
            const { user } = this.state;
            const { status, data } = response;

            switch (status) {
                case 401:
                    if (data.success !== true) {
                        window.gtag('event', 'LoginError', {
                            LoginAction: 'ValidateSecurityCode',
                            LoginValue: 'Invalid security code ' + status
                        });

                        this.setNotice('error', 'Invalid verification code');
                        this.setState({code: '', locked: false});
                    }
                    break;

                case 200:
                    if (user.isMfaAllowTrustedDevices === true) {
                        this.setState({
                            showTrustedSetup: true,
                            locked: false
                        });
                        return;
                    }
                    this.doNextAction();
                    break;

                default:
                    this.setError('ValidateSecurityCode', status);
                    break;
            }
        }).catch(err => this.setError('ValidateSecurityCode', err.message + ' ' + err.status));
    }

    requestSetupMfaEmail() {
        this.setState({
            notice: null,
            locked: true
        });

        request(
            '/api/auth/mfa/setup',
            JSON.stringify({
                method: 'email',
                email: this.state.email
            }),
            'application/json;charset=UTF-8'
        ).then(response => {
            const { status, data } = response;

            switch (status) {
                case 422:
                    // bad request - email empty or invalid
                    window.gtag('event', 'LoginError', {
                        LoginAction: 'SetupMfaEmail',
                        LoginValue: 'Invalid mfa email ' + status
                    });

                    this.setNotice('error', 'Invalid email address');
                    this.setState({locked: false});
                    break;

                case 200:
                    if (data.success === true) {
                        this.doAction('goToMfaEmail');
                        return;
                    }

                    this.setState({
                        emailPattern: data.allowedEmailPatterns,
                        locked: false
                    });
                    break;

                default:
                    this.setError('SetupMfaEmail', status);
                    break;
            }
        }).catch(err => this.setError('SetupMfaEmail', err.message + ' ' + err.status));
    }

    cancelTrustDevice() {
        this.doNextAction();
    }

    requestTrustDevice() {
        this.setState({
            notice: null,
            locked: true
        });

        request(
            '/api/auth/trustdevice'
        ).then(response => {
            const { status } = response;

            switch (status) {
                case 422:
                    // bad request - userId invalid
                    break;

                case 403:
                    // trusted devices not allowed

                    window.gtag('event', 'LoginError', {
                        LoginAction: 'TrustDevice',
                        LoginValue: (status === 422 ? 'Trust invalid userId ' : 'Trust not allowed ') + status
                    });

                    this.setState({
                        showTrustedError: true,
                        locked: false
                    });
                    break;

                case 200:
                    this.setState({
                        showTrustedSetup: false,
                        showTrustedError: false
                    });
                    this.setNotice('success', 'Successfully added device to Trusted Devices');
                    this.doAction('goToContinueToScreen');
                    break;

                default:
                    this.setError('TrustDevice', status);
                    break;
            }
        }).catch(err => this.setError('TrustDevice', err.message + ' ' + err.status));
    }

    async requestGetSecurityQuestions() {
        return new Promise((resolve, reject) => {
            request(
                '/buffer/ajax/loginController.php',
                getFormData({task:'taskGetQuestions'})
            )
                .then(data => resolve(data))
                .catch(error => reject(error));
        });
    }

    async requestSendMfaEmail() {
        return new Promise((resolve, reject) => {
            request(
                '/api/auth/mfa/sendcode',
                JSON.stringify({method: 'email'}),
                'application/json;charset=UTF-8'
            )
                .then(data => resolve(data))
                .catch(error => reject(error));
        });
    }

    getPaneForMode() {
        const {user, mode, locked, username, password, confirm, remember, email, defaultEmail, questions, question, questionId, answer, code, emailPattern} = this.state;
        const { theme } = this;

        switch (mode) {
            case 'goToLogin':
                return <PaneLogin
                        setNotice={() => this.setNotice()}
                        doAction={a => this.doAction(a)}
                        requestLogin={() => this.requestLogin()}
                        theme={theme}
                        username={username}
                        setUsername={u => this.setState({username:u})}
                        password={password}
                        setPassword={p => this.setState({password:p})}
                        remember={remember}
                        setRemember={r => this.setState({remember:r})}
                        locked={locked}
                />;

            case 'goToChangePassword':
                return <PaneChangePassword user={user}
                                           setMode={m => this.setMode(m)}
                                           password={password}
                                           setPassword={p => this.setState({password:p})}
                                           confirm={confirm}
                                           setConfirm={c => this.setState({confirm:c})}
                                           requestChangePassword={() => this.requestChangePassword()}
                                           locked={locked}
                />;

            case 'goToForgotPassword':
                return <PaneForgotPassword
                        setNotice={() => this.setNotice()}
                        doAction={a => this.doAction(a)}
                        username={username}
                        setUsername={u => this.setState({username:u})}
                        setPassword={p => this.setState({password:p})}
                        requestValidateUsername={() => this.requestValidateUsername()}
                        locked={locked}
                />;

            case 'goToSecurityQuestion':
                return <PaneSecurityQuestion
                        question={question}
                        answer={answer}
                        setAnswer={a => this.setState({answer:a})}
                        setNotice={() => this.setNotice()}
                        setUsername={u => this.setState({username:u})}
                        setPassword={p => this.setState({password:p})}
                        doAction={a => this.doAction(a)}
                        requestValidateSecurityAnswer={() => this.requestValidateSecurityAnswer()}
                        locked={locked}
                />;

            case 'goToRecoverSuccess':
                return <PaneRecoverSuccess
                        setNotice={() => this.setNotice()}
                        doAction={a => this.doAction(a)}
                        setUsername={u => this.setState({username:u})}
                        setPassword={p => this.setState({password:p})}
                        locked={locked}
                />;

            case 'goToFirstLogin':
                return <PaneFirstLogin setMode={m => this.setMode(m)}
                                       questions={questions}
                                       questionId={questionId}
                                       setQuestionId={id => this.setState({questionId:id})}
                                       answer={answer}
                                       setAnswer={a => this.setState({answer:a})}
                                       requestUpdateFirstLogin={() => this.requestUpdateFirstLogin()}
                                       locked={locked}
                />;

            case 'goToMfaSetup':
                return <PaneMfaSetup
                        setMode={m => this.setMode(m)}
                        locked={locked}
                />;

            case 'goToMfaSetupEmail':
                return <PaneMfaSetupEmail
                        setMode={m => this.setMode(m)}
                        email={email}
                        defaultEmail={defaultEmail}
                        updateEmail={e => this.setState({email:e})}
                        requestSetupMfaEmail={() => this.requestSetupMfaEmail()}
                        emailPattern={emailPattern}
                        locked={locked}
                />;

            case 'goToMfaEmail':
                return <PaneMfaEmail
                        user={user}
                        setMode={m => this.setMode(m)}
                        email={email}
                        code={code}
                        setSecurityCode={c => this.setState({code:c})}
                        resendMfaEmail={() => this.resendMfaEmail()}
                        requestValidateSecurityCode={() => this.requestValidateSecurityCode()}
                        locked={locked}
                />;

            case 'goToContinueToScreen':
                return <PaneContinueToScreen doNextAction={() => this.doNextAction()} />;

            default:
                return null;
        }
    }

    render() {
        const { ready, notice, error, errorTitle, errorMessage, showTrustedSetup, showTrustedError, maintenance, locked } = this.state;
        const { theme } = this;
        const year = new Date().getFullYear();
        const logoWidth = !ready || theme.site_logo_w <= 0 ? '' : theme.site_logo_w;
        const logoHeight = !ready || theme.site_logo_h <= 0 ? '' : theme.site_logo_h;

        return <Fragment>
            <div className="App">
                <div className="AppAccent" />
                {ready && <div className="AppLogo" style={{textAlign:'center'}}>
                    <div>
                        <img src={theme.site_logo}
                             width={logoWidth}
                             height={logoHeight}
                             alt="customerlogo"
                             className="AppLogoImg"
                        />
                    </div>
                    {!theme.isProduction && <WarningIcon className="AppPreProdWarning"/>}
                    {notice && <div className="AppMessage">{notice}</div>}
                </div>}
                {ready
                    ? this.getPaneForMode()
                    : <div className="AppBody" />}
                {ready && <Footer theme={theme} />}
                <div className="AppCopyright">Copyright &copy; {year}. All rights reserved.</div>
            </div>
            {showTrustedSetup && <Trusted showTrustedError={showTrustedError}
                                          cancel={() => this.cancelTrustDevice()}
                                          request={() => this.requestTrustDevice()}
                                          locked={locked}
            />}
            {error && <Error theme={theme} title={errorTitle} message={errorMessage} />}
            {maintenance && <Maintenance />}
        </Fragment>;
    }
}