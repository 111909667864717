import React, {Fragment} from 'react';
import {AppButton} from '../Controls';
import './PaneContinueToScreen.css';

export default function PaneContinueToScreen(props) {
    const { doNextAction } = props;

    return <Fragment>
        <div className="AppBody">
            <div className="AppBodyCentered PaneContinueToScreen">
                <div className="AppControls">
                    <AppButton type="rounded"
                               text="Continue"
                               onClick={() => doNextAction()}
                    />
                </div>
            </div>
        </div>
    </Fragment>;
}