export default async function request(url, formData, contentType) {
    return new Promise((resolve, reject) => {
        const params = {
            'method': 'POST',
            'headers': {},
            'body': formData
        };

        if (contentType) {
            params.headers['Content-Type'] = contentType;
        }

        let status;

        fetch(
            url || '/buffer/ajax/loginController.php',
            params
        ).then(response => {
            status = response.status;
            return response.json();
        }).then(data => {
            resolve({status:status, data:data});
        }).catch(error => {
            reject({status:status, error:error});
        });
    });
}

export function getFormData(json) {
    const formData = new FormData();
    let prop;

    for (prop in json) {
        if (json.hasOwnProperty(prop)) {
            formData.append(prop, json[prop]);
        }
    }

    return formData;
}

