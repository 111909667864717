import React from 'react';
import {AppButton} from './Controls';
import {ReactComponent as WarningIcon} from '../img/icon/exclamation-triangle-solid.svg';
import './Error.css';

export default function Error(props) {
    let {title, message} = props;
    const {theme} = props;

    const phone = theme ? theme.site_support_phone : '877-585-7366';
    const email = theme ? theme.site_support_email : 'customer@i3screen.com';

    title = title || 'Server Error';
    message = message || 'If you are unable to login, or need an account, you may call [phone] for assistance or contact Customer Service at <a href="mailto:[email]">[email]</a>';
    message = message.replaceAll('[phone]', phone);
    message = message.replaceAll('[email]', email);

    return <div className="AppMask">
        <div className="AppDialog">
            <div className="AppDialogBody">
                <WarningIcon className="AppWarningIcon"/>
                <div className="Header16" style={{marginTop:'5px'}}>
                    {title}
                </div>
                <div className="AppDialogSection" dangerouslySetInnerHTML={{__html:message}} />
                <div className="AppDialogSection">
                    <AppButton type="rounded"
                               text="Try Again"
                               onClick={() => window.location.reload()}
                    />
                </div>
            </div>
        </div>
    </div>;
}