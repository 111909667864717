import React, {Fragment} from 'react';
import {AppSecurityCode, AppButton} from '../Controls';
import {ReactComponent as Spinner} from "../../img/icon/Rolling-1s-44px.svg";
import './PaneMfaEmail.css';

export default function PaneMfaEmail(props) {
    const {user, setSecurityCode, requestValidateSecurityCode, resendMfaEmail, code, email, locked} = props;
    const btnDisabled = code.length < 6;

    return <Fragment>
        <div className="AppHeader">
            {user.isMfaSetUpRequired ? 'Set up Email Authentication' : 'Email Authentication'}
        </div>
        {user.isMfaSetUpRequired && <div className="AppHeader">Step 2.</div>}
        <div className="AppBody">
            <div className="AppBodyCentered PaneMfaEmail">
                <div className="PaneSubHeader PaneMfaEmailPaneSubHeader">
                    Enter the six-digit verification code that was sent to {email}{user.isMfaSetUpRequired && ' in the previous step'}.
                </div>
                <div className="Header16">Didn't get your code?</div>
                <div style={{marginBottom:'25px'}}>
                    <AppButton type="link Header16"
                               text="Resend code"
                               onClick={() => resendMfaEmail()}
                    />
                </div>
                <div className="SubHeader14" style={{marginBottom:'10px'}}>Enter Verification Code</div>
                <AppSecurityCode value={code} onChange={c => setSecurityCode(c)} />
                <div className="AppControls">
                    {locked && <Spinner />}
                    {!locked && <AppButton type="rounded"
                                           text="Verify"
                                           disabled={locked || btnDisabled}
                                           onClick={() => requestValidateSecurityCode()}
                    />}
                </div>
            </div>
        </div>
    </Fragment>;
}